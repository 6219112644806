import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  eventModal: false,
  choiceModal: false,
  issueDefinitveModal: false
}

export const modalsSlice = createSlice({
  name: 'probability/modal',
  initialState,
  reducers: {
    openEvent: (state) => {
      state.issueDefinitveModal = false
      state.choiceModal = false
      state.eventModal = true
    },
    closeEvent: (state) => {
      state.eventModal = false
    },
    openChoice: (state) => {
      state.issueDefinitveModal = false
      state.eventModal = false
      state.choiceModal = true
    },
    closeChoice: (state) => {
      state.choiceModal = false
    },
    openIssueDefinitive: (state) => {
      state.eventModal = false
      state.choiceModal = false
      state.issueDefinitveModal = true
    },
    closeIssueDefinitive: (state) => {
      state.issueDefinitveModal = false
    }
  }
})

export const {
  openEvent,
  closeEvent,
  openChoice,
  closeChoice,
  openIssueDefinitive,
  closeIssueDefinitive
} = modalsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectTree = (state) => state.tree.tree
export const selectEventModal = (state) => state.probabilityModal.eventModal
export const selectChoiceModal = (state) => state.probabilityModal.choiceModal
export const selectIssueDefinitiveModal = (state) => state.probabilityModal.issueDefinitveModal

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState())
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount))
//   }
// }

export default modalsSlice.reducer
