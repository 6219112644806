import React from 'react'
// import logo from './logo.svg'
import Tree from './features/Tree/Tree'
import MenuBar from './features/MenuBar/index'
import Introduction from './Components/Introduction'
import { useSelector } from 'react-redux'
// import Choice from './features/Modals/Choice'
// import { Counter } from './features/counter/Counter'
import { selectTreeState } from './features/Tree/treeSlice'
import './App.css'
import './features/Tree/tree.css'
// import DropDown from './features/MenuBar/DropDownBtn'

function App() {
  const treeState = useSelector(selectTreeState)
  return (
    <>
      {treeState?.visibility === 'hidden' ? <Introduction /> : <Tree />}
      <div className="fixed  bottom-5 flex justify-center flex-col w-full" style={{}}>
        <footer className="sm:w-full md:w-full lg:w-full xl:w-9/12 self-center p-5 bg-indigo-600 text-center text-white shadow-lg rounded-lg ">
          <MenuBar />
        </footer>
      </div>
    </>
  )
}

export default App
