import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  visibility: 'hidden',
  FIRST_UPDATE: true,
  tree: {
    name: 'FIRST_EVER',
    TOP_OF_THE_HIERARCHY: true,
    type: '',
    children: []
  },
  currentNode: '',
  firstNodeType: '',
  trackIds: [],
  tempTrackIds: []
}

export const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    firstUpdateToTree: (state, { payload }) => {
      state.tree.name = payload.name
      state.tree.type = payload.type
      state.tree.children.push(...payload.values)
      state.visibility = true
      // state.trackIds.push(...payload.ids)
    },
    firstUpdateToTreeFromFile: (state, { payload }) => {
      state.tree.name = payload.name
      state.tree.type = payload.type
      state.tree.children.push(...payload.children)
      state.visibility = true
      // state.trackIds.push(...payload.ids)
    },
    updateIssue: (state, { payload }) => {
      state.tree = payload
    },
    falsifyFirstUpdate: (state) => {
      state.FIRST_UPDATE = false
    },
    updateTree: (state, { payload }) => {
      state.tree = payload
    },
    deleteBranch: (state, { payload }) => {
      // let newPayload = {...payload}
      state.tree = payload
    },
    setCurrentNode: (state, { payload }) => {
      state.currentNode = payload
    },
    resetCurrentNode: (state) => {
      state.currentNode = null
    },

    /** EVERYTHING RELATED TO state.trackIds */

    addTrackIds: (state, { payload }) => {
      state.trackIds.push(...payload)
    },
    addTrackId: (state, { payload }) => {
      state.trackIds.push(payload.id)
    },
    addTrackIdAfterIssueDelete: (state, { payload }) => {
      const idExists = state.trackIds.indexOf(payload.id) > -1
      if (!idExists) state.trackIds.push(payload.id)
    },
    deleteTrackId: (state, { payload }) => {
      state.trackIds = state.trackIds.filter((item) => item !== payload.id)
    },
    deleteTrackIds: (state, { payload }) => {
      state.trackIds = state.trackIds.filter((item) => !payload.ids.includes(item))
    },
    emptyTrackIds: (state) => {
      state.trackIds = []
    }
  }
})

export const {
  firstUpdateToTree,
  updateTree,
  setCurrentNode,
  falsifyFirstUpdate,
  addTrackIds,
  deleteBranch,
  firstUpdateToTreeFromFile,
  updateIssue,
  deleteTrackId,
  addTrackId,
  deleteTrackIds,
  emptyTrackIds,
  addTrackIdAfterIssueDelete,
  resetCurrentNode
} = treeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTree = (state) => state.tree.tree
export const selectTreeState = (state) => state.tree
export const selectVisibility = (state) => state.tree.visibility
export const selectCurrentNode = (state) => state.tree.currentNode
export const selectTrackIds = (state) => state.tree.trackIds

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState())
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount))
//   }
// }

export default treeSlice.reducer
