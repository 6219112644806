// import { useFormik } from 'formik'

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import {
  goToTopNode,
  addChildrenTree,
  TREE_TYPES,
  bfsAndInsert,
  topOfHierarchy
} from '../../lib/tree'
import {
  selectNumberOfForms,
  selectAddInEveryBranch,
  chooseNumberOfForms,
  resetAddEverybranch,
  selectFormForUpdate,
  resetFormForUpdate,
  resetAddBetweenBranch
} from './formsSlice'
import {
  firstUpdateToTree,
  selectTree,
  falsifyFirstUpdate,
  selectCurrentNode,
  selectTreeState,
  updateTree,
  addTrackIds,
  deleteTrackId,
  selectTrackIds,
  emptyTrackIds,
  resetCurrentNode
} from './../Tree/treeSlice'
import {
  initialValue,
  giveNumberOfFormToValidate,
  sumOfProbabilities,
  allFormsHaveProbability,
  allEcartTypeAreGtThenZero,
  addEstimation,
  ecartTypeRangeError
} from './EventFormikFns'
import { addAttrAndIds, extractFormValues, FIELD_REQUIRED_ERROR } from './../../lib/formik'

import { closeEvent } from '../Modals/modalSlice'
import cloneDeep from 'lodash.clonedeep'
import RadioButtons from '../Modals/RadioButtons'
const classes = {
  input:
    'mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
}

const ECART_TYPE_ERROR = 'Tous les écart type doivent être au dessus ou égale à zero'
// eslint-disable-next-line
const ECART_TYPE_RANGE_ERROR = "Erreur d'écart type"
const FORM_NAMES = ['formOne', 'formTwo', 'formThree', 'formFour', 'formFive']
/**
 *
 *
 * EVENT FORM
 *
 *
 */
function EventNameForm({ formik }) {
  return (
    <>
      <div className="border border-gray-300 rounded-md px-3 py-2 mb-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
        <label htmlFor="name" className="block text-xs font-medium text-gray-900">
          Nom
        </label>
        <input
          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
          type="text"
          name="event.name"
          value={formik.values.event.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder="pile"
        />
        {formik?.touched?.event && formik.errors.event && (
          <div style={{ color: 'red' }}>
            <small>{formik.errors.event.name}</small>
          </div>
        )}
      </div>
    </>
  )
}

function epmtyInitValues() {
  return { name: '', probability: '', ecartType: '' }
}

/**
 * MAIN MODAL OF THE PAGE
 * This modal should accept two children Button
 * One to close the button
 * One to Submit the form
 */

// export default function MainForm({ formik, children }) {
export default function MainForm({ children }) {
  const dispatch = useDispatch()

  const currentNode = useSelector(selectCurrentNode)
  const addInEveryBranch = useSelector(selectAddInEveryBranch)
  const treeState = useSelector(selectTreeState)
  const trackIds = useSelector(selectTrackIds)
  const tree = useSelector(selectTree)
  const numberOfForms = useSelector(selectNumberOfForms)
  const formForUpdate = useSelector(selectFormForUpdate)
  const addBetweenBranch = useSelector((state) => state.forms.addBetweenBranch)

  const [probabilitySum, setProbabilitySum] = useState('')
  const [probabilityError, setProbabilityError] = useState('')
  const [ecartTypeError, setEcartTypeError] = useState('')
  const [overFormNumbers, setLocalFormNumbers] = useState('')

  const updateSubmit = ({ payload, values }) => {
    let res = extractFormValues(values, numberOfForms, { ignore: 'event' })
    const copyTree = cloneDeep(currentNode)
    if (!topOfHierarchy(copyTree)) copyTree.data.childrenTree.name = values?.event?.name
    if (topOfHierarchy(copyTree)) copyTree.data.name = values?.event?.name
    res.forEach((elem, index) => {
      const attributes = copyTree?.data?.children[index].attributes
      copyTree.data.children[index].attributes.from = payload?.values[index]?.attributes?.from
      copyTree.data.children[index].attributes.to = payload?.values[index]?.attributes?.to
      copyTree.data.children[index].name = payload?.values[index]?.name
    })
    const ret = goToTopNode(copyTree)
    dispatch(updateTree(ret.data))
    dispatch(resetFormForUpdate())
    dispatch(closeEvent())
    dispatch(resetCurrentNode())
  }

  const updateToEveryBranch = ({ values }) => {
    let formValues = extractFormValues(values, numberOfForms, { ignore: 'event' })
    const newIds = [] /* new ids, it will replace trackIDS current state */
    const treeCopy = cloneDeep(tree)
    let newTree
    trackIds.forEach((elem) => {
      let forms = addAttrAndIds(formValues, { TYPE: TREE_TYPES.EVENT })
      formValues = addEstimation(forms.addedFroms)
      const payload = { name: values.event.name, values: formValues, ids: forms.ids }
      newIds.push(...payload.ids)
      newTree = bfsAndInsert(elem, treeCopy, payload, { type: TREE_TYPES.EVENT })
    })
    dispatch(emptyTrackIds())
    dispatch(addTrackIds(newIds))
    dispatch(updateTree(newTree))
    dispatch(resetAddEverybranch())
    dispatch(closeEvent())
    dispatch(resetCurrentNode())
  }

  const addInBetweenBranchSubmit = ({ values, payload }) => {
    const formValues = extractFormValues(values, numberOfForms, { ignore: 'event' })
    const copyTree = cloneDeep(currentNode)
    let tempStockChildrens
    //
    if (copyTree?.data?.children) tempStockChildrens = [...copyTree.data.children]
    const tempStockChildrenTree = { ...copyTree?.data?.childrenTree }

    copyTree.data.childrenTree = {
      name: payload.name,
      type: TREE_TYPES?.EVENT
    }
    copyTree?.data?.children.splice(0)
    payload.values[0].childrenTree = {
      ...tempStockChildrenTree
    }
    const trackIds = payload?.ids.filter((id) => id !== payload?.values[0]?.attributes?._id)
    payload.values[0].children.push(...tempStockChildrens)
    copyTree?.data?.children.push(...payload.values)
    // add old children to first form
    // add childrenTree
    // spread others
    const ret = goToTopNode(copyTree)
    dispatch(updateTree(ret.data))
    dispatch(addTrackIds(trackIds))
    dispatch(resetFormForUpdate())
    dispatch(closeEvent())
    dispatch(resetCurrentNode())
    dispatch(resetAddBetweenBranch())
    dispatch(chooseNumberOfForms({ numberOfForms: 2 }))
  }

  const getEventName = (node) => {
    if (addBetweenBranch) return { name: '' }
    if (node?.data?.TOP_OF_THE_HIERARCHY) return { name: node?.data?.name }
    return { name: currentNode?.data?.childrenTree?.name || '' }
  }

  const formik = useFormik({
    initialValues: {
      event: getEventName(currentNode),
      formOne: addBetweenBranch
        ? { ...epmtyInitValues() }
        : { ...initialValue(currentNode?.data?.children[0]) },
      formTwo: addBetweenBranch
        ? { ...epmtyInitValues() }
        : { ...initialValue(currentNode?.data?.children[1]) },
      formThree: addBetweenBranch
        ? { ...epmtyInitValues() }
        : { ...initialValue(currentNode?.data?.children[2]) },
      formFour: addBetweenBranch
        ? { ...epmtyInitValues() }
        : { ...initialValue(currentNode?.data?.children[3]) },
      formFive: addBetweenBranch
        ? { ...epmtyInitValues() }
        : { ...initialValue(currentNode?.data?.children[4]) }
    },
    validationSchema: Yup.object({
      ...giveNumberOfFormToValidate(numberOfForms),
      event: Yup.object({ name: Yup.string().required(FIELD_REQUIRED_ERROR) })
    }),
    onSubmit: (values) => {
      setProbabilityError((prev) => '')
      setEcartTypeError((prev) => '')

      const sumOfProbability = sumOfProbabilities(values, numberOfForms)

      if (Number(sumOfProbability) === 100) setProbabilityError((prev) => '')
      if (sumOfProbability !== 100)
        return setProbabilityError((prev) => 'La somme de la probabilité doit faire 100')
      if (!allEcartTypeAreGtThenZero(values, numberOfForms))
        return setEcartTypeError((prev) => ECART_TYPE_ERROR) // Ecart type plus grand que zero

      let res = extractFormValues(values, numberOfForms, { ignore: 'event' })

      let forms = addAttrAndIds(res, { TYPE: TREE_TYPES.EVENT })
      const formvalues = addEstimation(forms.addedFroms)
      if (ecartTypeRangeError(formvalues)) {
        // eslint-disable-next-line quotes
        setEcartTypeError((prev) => "Erreur d'écart type")
        return
      }

      const payload = {
        name: values.event.name,
        values: formvalues,
        ids: forms.ids
      }

      if (treeState.FIRST_UPDATE === true) {
        dispatch(firstUpdateToTree({ ...payload, type: TREE_TYPES?.EVENT }))
        dispatch(falsifyFirstUpdate())
        dispatch(addTrackIds(payload.ids))
        dispatch(closeEvent())

        return
      }

      if (addBetweenBranch === true) {
        addInBetweenBranchSubmit({ values, payload })
        return
      }

      if (addInEveryBranch === true) {
        updateToEveryBranch({ values })
        return
      }
      if (formForUpdate === true) {
        // on update
        updateSubmit({ payload, values })
        return
      }
      // push to children
      currentNode.data.children.push(...payload.values)
      currentNode.data.childrenTree = {
        ...addChildrenTree({ name: payload.name, type: TREE_TYPES.EVENT })
      }
      // remonter au hierarchy node
      const ret = goToTopNode(currentNode)
      dispatch(updateTree(ret.data))
      dispatch(deleteTrackId({ id: currentNode?.data.attributes?._id }))
      dispatch(addTrackIds(payload.ids))
      dispatch(closeEvent())
    }
  })

  function submitAddInEveryBranchs() {}
  return (
    <>
      {/* <TaillWind /> */}
      <form className="" onSubmit={formik.handleSubmit}>
        <EventNameForm formik={formik} />
        <div className="py-4 sm:py-5 sm:grid  sm:gap-4 sm:px-6">
          {!formForUpdate && <RadioButtons />}
        </div>
        {probabilityError && (
          <div style={{ color: 'red' }}>
            <small>{probabilityError}</small>
          </div>
        )}
        {ecartTypeError && (
          <div style={{ color: 'red' }}>
            <small>{ecartTypeError}</small>
          </div>
        )}
        {[...Array(numberOfForms)].map((x, idx) => (
          <Form key={FORM_NAMES[idx]} formik={formik} formName={FORM_NAMES[idx]} />
        ))}
        {/* <button type="submit">Submit</button> */}
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          {/* <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            onClick={() => {// dispatch(closeEvent())}}> Submit </button> */}
          {children}
          {/* <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => {dispatch(closeEvent())}} ref={cancelButtonRef} >Cancel</button> */}
        </div>
      </form>
    </>
  )
}

/**
 *
 *
 * BRANCHES FORM
 */

/**
 *
 * FORM One to Five
 * <Form formik={formik} formName={"formOne"} />
 * <Form formik={formik} formName={"formTwo"} />
 * <Form formik={formik} formName={"formThree"} />
 * <Form formik={formik} formName={"formFour"} />
 * @returns FORM
 */

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Form({ formik, formName }) {
  return (
    <div
      className="lg:grid lg:grid-cols-12 lg:gap-x-5"
      style={{ gridTemplateColumns: 'repeat(8, 1fr)' }}>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-1 px-4 space-y-6 sm:p-1">
            {/*  */}
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label
                  htmlFor={`${formName}.name`}
                  className="block text-sm font-medium text-gray-700">
                  Nom
                </label>
                <Name formik={formik} formName={formName} name={`${String(formName)}.name`} />
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label
                  htmlFor={`${formName}.probability`}
                  className="block text-sm font-medium text-gray-700">
                  Probabiilté
                </label>
                <Probability formik={formik} formName={formName} name={`${formName}.probability`} />
              </div>

              <div className="col-span-6 sm:col-span-2">
                <label
                  htmlFor={`${formName}.ecartType`}
                  className="block text-sm font-medium text-gray-700">
                  Ecart type
                </label>
                <EcartType
                  formik={formik}
                  formName={formName}
                  name={`${String(formName)}.ecartType`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const InputText = ({ formik, name, formName }) => {
  if (formik && name) {
    return (
      <input
        type="text"
        name={name}
        value={formik.values[`${formName}`].name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        className={classes.input}
        placeholder="Jane Doe"
      />
    )
  }
}

function Name({ formik, name, formName }) {
  return (
    <>
      <input
        type="text"
        name={name}
        value={formik.values[`${formName}`]?.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        className={classes.input}
        placeholder="pile"
      />
      {formik?.touched?.[formName] && formik?.errors[`${formName}`]?.name && (
        <div style={{ color: 'red' }}>
          <small>{formik.errors[`${formName}`].name}</small>
        </div>
      )}
    </>
  )
}

function Probability({ formik, name, formName }) {
  return (
    <>
      <input
        list="probability"
        type="text"
        name={name}
        value={formik.values[`${formName}`]?.probability}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        className={classes.input}
        placeholder="50"
        pattern="[0-9]+"
      />
      <datalist id="probability">
        <option value="10" />
        <option value="20" />
        <option value="30" />
        <option value="40" />
        <option value="50" />
        <option value="60" />
        <option value="70" />
        <option value="80" />
        <option value="90" />
      </datalist>
      {formik?.touched?.[`${formName}`] && formik.errors?.[`${formName}`]?.probability && (
        <div style={{ color: 'red' }}>
          <small>{formik?.errors?.[`${formName}`]?.probability}</small>
        </div>
      )}
    </>
  )
}

const ECART_TYPE_DATA_LIST = Array.from(Array(50).keys())
function EcartType({ formik, name, formName }) {
  return (
    <>
      <input
        type="text"
        list="ecartType"
        name={name}
        value={formik.values[`${formName}`].ecartType}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        className={classes.input}
        placeholder="5"
        pattern="[0-9]+"
      />
      {formik?.touched?.[`${formName}`] && formik?.errors?.[`${formName}`] && (
        <div style={{ color: 'red' }}>
          <small>{formik?.errors?.[`${formName}`]?.ecartType}</small>
        </div>
      )}
      <datalist id="ecartType">
        {ECART_TYPE_DATA_LIST.map((elem) => elem % 2 === 0 && <option key={elem} value={elem} />)}
      </datalist>
    </>
  )
}
