import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { resetFormForUpdate, selectFormForUpdate, selectNumberOfForms } from './formsSlice'
import {
  deleteTrackId,
  firstUpdateToTree,
  selectCurrentNode,
  selectTree,
  updateIssue,
  updateTree
} from './../Tree/treeSlice'
import { v4 as uuidv4 } from 'uuid'
import { goToTopNode, TREE_TYPES } from '../../lib/tree'
import cloneDeep from 'lodash.clonedeep'
import { closeIssueDefinitive, selectIssueDefinitiveModal } from '../Modals/modalSlice'
import { FIELD_REQUIRED_ERROR } from '../../lib/formik'

const classes = {
  input: 'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm'
}
export default function MainForm({ children }) {
  const tree = useSelector(selectTree)
  const currentNode = useSelector(selectCurrentNode)
  const formForUpdate = useSelector(selectFormForUpdate)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      name:
        (currentNode?.data?.attributes?.type === TREE_TYPES?.ISSUE && currentNode?.data?.name) ||
        '',
      comment:
        (currentNode?.data?.attributes?.type === TREE_TYPES?.ISSUE && currentNode?.data?.comment) ||
        ''
    },
    validationSchema: Yup.object({
      name: Yup.string().min(1, 'Le nom saisi est trop court.').required(FIELD_REQUIRED_ERROR),
      comment: Yup.string()
        .min(5, 'Le commentaire saisi est trop court.      ')
        .required(FIELD_REQUIRED_ERROR)
    }),
    onSubmit: (values) => {
      const copyTree = cloneDeep(currentNode)
      // update
      if (copyTree.data.attributes.type === TREE_TYPES.ISSUE) {
        copyTree.data.name = values.name
        copyTree.data.comment = values.comment
        const ret = goToTopNode(copyTree)
        dispatch(updateIssue(ret.data))
        dispatch(closeIssueDefinitive())
        dispatch(resetFormForUpdate())
        return
      }
      const payload = {
        name: values.name,
        comment: values.comment,
        attributes: { _id: uuidv4(), type: TREE_TYPES.ISSUE },
        tyep: TREE_TYPES?.ISSUE,
        children: []
      }
      copyTree.data.childrenTree = {
        type: TREE_TYPES?.ISSUE
      }
      copyTree.data.children.push(payload)
      const ret = goToTopNode(copyTree)

      // dispatch(updateIssue(ret.data))
      // dispatch(addIssueInfosToState({ _id: payload._id }))
      dispatch(deleteTrackId({ id: copyTree?.data?.attributes?._id }))
      dispatch(updateTree(ret.data))
      // dispatch(addIssueInfosToState({ _id: payload.attributes?._id }))
      dispatch(closeIssueDefinitive())
    }
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <Name formik={formik} name={'name'} />
      <Comment formik={formik} name="comment" />
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        {children}
      </div>
    </form>
  )
}

function Form({ formik, formName }) {
  return (
    <>
      <Name formik={formik} name="name" />
    </>
  )
}

function Name({ formik, name }) {
  return (
    <>
      <div className="border border-gray-300 rounded-md px-3 py-2 mb-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
        <label htmlFor="name" className="block text-xs font-medium text-gray-900">
          Name
        </label>
        <input
          type="text"
          name={name}
          value={formik.values.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          className={classes.input}
          placeholder="Jane Doe"
        />
        {formik.touched?.name && formik?.errors?.name && (
          <div style={{ color: 'red' }}>
            <small>{formik?.errors.name}</small>
          </div>
        )}
      </div>
    </>
  )
}

export function Comment({ formik, name }) {
  return (
    <div className="border border-gray-300 rounded-md px-3 py-2 mb-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
        Add your comment
      </label>
      <div className="mt-1">
        <textarea
          rows={4}
          name={name}
          id={name}
          value={formik.values.comment}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          className="shadow-sm  block w-full sm:text-sm border-0 border-white focus:outline-none focus:ring-0 rounded-md border-transparent p-0 resize-none"
        />
      </div>
      {formik.touched?.comment && formik?.errors?.comment && (
        <div style={{ color: 'red' }}>
          <small>{formik?.errors.comment}</small>
        </div>
      )}
    </div>
  )
}
