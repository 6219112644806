import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import { selectCurrentNode, setCurrentNode, updateTree } from './treeSlice'
import { openChoice, openEvent } from './../Modals/modalSlice'
import { PencilAltIcon } from '@heroicons/react/solid'

import './tree.css'
import { goToTopNode, isIssueDefinitive, nextChildIsIssue, TREE_TYPES } from '../../lib/tree'

/** COMPONENTS */
import ChildlessNode from './ChildlessNode'
import NodeWithChild from './NodeWithChild'
import IssueNode, { IssueNodeFrame } from './IssueNode'
import { chooseNumberOfForms, shouldAddFormForUpdate } from '../Forms/formsSlice'

export const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  hierarchyPointNode
}) => {
  let position = getPosition({ nodeDatum, hierarchyPointNode })
  let length = hierarchyPointNode?.parent?.children?.length - 1
  const bgClass = hierarchyPointNode?.parent === null ? 'bg-green-200' : 'bg-indigo-200'
  // React.useEffect(() => {
  //   console.log({ nodeDatum })
  // }, [])
  return (
    <g>
      {/* {nodeDatum?.probability && <circle r={15}>jiajiajaijaijaiajajia</circle>} */}
      {/* {nodeDatum?.TOP_OF_THE_HIERARCHY && <circle r={15}>jiajiajaijaijaiajajia</circle>} */}
      <circle r={15} fill="#4c51bf" stroke="#4c51bf;" />
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        {nodeDatum.children && nodeDatum.children.length > 0 && (
          <>
            <div
              className={`box-border border-1 border-indigo-700 ${bgClass}`}
              style={{
                border: () => nextChildIsIssue(nodeDatum) && '1px solid transparent', //  one children === next child is a issue definitive
                marginTop: '35px',
                borderRadius: '5px',
                // backgroundColor: '#dedede',
                overflow: 'unset',
                position: 'relative'
              }}>
              {showNameAndProbability({ nodeDatum, className: 'hasChild' })}
              {/*  */}
              <NodeWithChild hierarchyPointNode={hierarchyPointNode} nodeDatum={nodeDatum} />
              {nodeDatum?.TOP_OF_THE_HIERARCHY ? (
                <SubTreeTitle nodeDatum={nodeDatum} hierarchyPointNode={hierarchyPointNode} />
              ) : (
                nodeDatum?.children.length > 1 && (
                  <SubTreeTitle nodeDatum={nodeDatum} hierarchyPointNode={hierarchyPointNode} />
                )
              )}
            </div>
          </>
        )}
        {/* si il n'y a pas d'enfant */}
        {nodeDatum?.children?.length === 0 && (
          <>
            <ChildlessNode hierarchyPointNode={hierarchyPointNode} />
            {nodeDatum?.attributes?.type !== TREE_TYPES.ISSUE &&
              showNameAndProbability({ nodeDatum, className: 'childless' })}
          </>
        )}
        {/* si issue definitive */}
        {nodeDatum?.attributes?.type === TREE_TYPES.ISSUE && (
          <>
            <IssueNode hierarchyPointNode={hierarchyPointNode} />

            {isIssueDefinitive(nodeDatum) && (
              <IssueNodeFrame nodeDatum={nodeDatum} hierarchyPointNode={hierarchyPointNode} />
            )}
          </>
        )}
      </foreignObject>
    </g>
  )
}

function SubTreeTitle({ nodeDatum, hierarchyPointNode }) {
  const hoverClass =
    'flex justify-center items-center p-2  group font-sans font-normal box-border border-1 rounded-lg bg-gradient-to-r from-indigo-400 to-indigo-500 text-white'
  const hoverClassTopOfHierarchy =
    'flex justify-center items-center p-2  group font-sans font-normal box-border border-1 rounded-lg bg-emerald-400 text-white'
  const [hover, setHover] = React.useState(false)
  const dispatch = useDispatch()
  const currentNode = useSelector(selectCurrentNode)

  const getClass = (node) => {
    return nodeDatum?.TOP_OF_THE_HIERARCHY ? hoverClassTopOfHierarchy : hoverClass
  }

  // console.log({ nodeDatum })
  const onClick = () => {
    const copyTree = cloneDeep(currentNode)
    if (nodeDatum?.TOP_OF_THE_HIERARCHY) {
      if (copyTree?.data?.type === TREE_TYPES?.EVENT) dispatch(openEvent())
      if (copyTree?.data?.type === TREE_TYPES?.CHOICE) dispatch(openChoice())
    } else {
      if (copyTree?.data?.childrenTree?.type === TREE_TYPES?.CHOICE) dispatch(openChoice())
      if (copyTree?.data?.childrenTree?.type === TREE_TYPES?.EVENT) dispatch(openEvent())
    }
    dispatch(chooseNumberOfForms({ numberOfForms: copyTree?.data?.children?.length }))
    dispatch(shouldAddFormForUpdate())
  }
  return (
    <>
      <p
        className={!hover ? 'flex justify-center p-2 font-sans font-normal' : getClass(nodeDatum)}
        onMouseEnter={() => {
          setHover(true)
          dispatch(setCurrentNode(hierarchyPointNode))
        }}
        onMouseLeave={() => setHover(false)}
        onClick={() => onClick()}>
        {hover ? (
          <>
            <PencilAltIcon className="mr-1 h-5 w-5 text-white" aria-hidden="true" />
            modifier
          </>
        ) : nodeDatum?.TOP_OF_THE_HIERARCHY ? (
          nodeDatum?.name
        ) : (
          nodeDatum?.childrenTree?.name && nodeDatum?.childrenTree?.name
        )}
      </p>
    </>
  )
}

function getPosition({ hierarchyPointNode, nodeDatum }) {
  const id = nodeDatum?.attributes?._id
  const position = hierarchyPointNode?.parent?.children
    .map((child) => child?.data?.attributes?._id)
    .indexOf(id)
  return position
}

/** */
function showNameAndProbability({ nodeDatum, className }) {
  const classs = {
    childless: {
      name: 'absolute mt-[1rem]  w-[3em] ml-[-50px] text-sm whitespace-nowrap',
      probability: 'absolute mt-[1rem] w-[3em] ml-[50px] text-sm'
    },
    hasChild: {
      name: 'absolute mt-[-1rem] w-[3em] ml-[-50px] text-sm whitespace-nowrap',
      probability: 'absolute mt-[-1rem] w-[3em] ml-[50px] text-sm'
    }
  }
  if (nodeDatum?.TOP_OF_THE_HIERARCHY) {
    return
  }
  return (
    <div className="flex justify-center items-center flex-row relative">
      <p className={classs[className]?.name} dir="rtl">
        {nodeDatum?.name || 'pile'}
      </p>
      {/* <span className="absolute mt-[-1rem] px-[5rem]">+++++++++</span> */}
      {nodeDatum?.probability && (
        <p className={classs[className]?.probability}>
          {nodeDatum?.attributes?.from}-{nodeDatum?.attributes?.to}
        </p>
      )}
    </div>
  )
}
