/**
 *
 * @param {*} node | currentNode
 * @returns Boolean
 */
export const topOfHierarchy = (node) => node?.data?.TOP_OF_THE_HIERARCHY

/**
 *
 * @param {currentNode} reference
 * @returns
 */
export function goToTopNode(reference) {
  if (topOfHierarchy(reference)) return reference
  let loop = reference.parent
  let ret
  while (loop) {
    if (Object.entries(loop).length === 0) break
    ret = loop
    loop = loop.parent
  }
  return ret
}

export const firstEverChild = (node) => node?.parent?.parent === null
export const firstParent = (node) => node?.parent === null

export const TREE_TYPES = {
  EVENT: 'EVENT',
  CHOICE: 'CHOICE',
  ISSUE: 'ISSUE'
}

export const CHILDREN_TREE_STRUCT = {
  children: [],
  childrenTree: {
    name: '',
    CHILD_TREE: true,
    type: '' // CHOICE | ISSUE | EVENTUALITY
  }
}

export function addChildrenTree({ name, type } = {}) {
  return {
    name,
    type,
    CHILD_TREE: true
  }
}

/**
 *
 * @param {*} tree | global state of tree
 * @returns return true if tree was not updated
 */

export function treeIsEmpty(tree) {
  return tree.FIRST_UPDATE === true
}

/**
 *
 * @param {*} node
 * @returns verify if next child is issue definitive
 */
export function nextChildIsIssue(node) {
  return node.children.length > 1
}

/**
 *
 * @param {*} nodeDatum
 * @returns verify if node is issue definitive returns BOOLEAN
 */
export function isIssueDefinitive(nodeDatum) {
  return nodeDatum?.attributes?.type === TREE_TYPES.ISSUE
}

/**
 *
 * @param {*} res |  calculateResult()
 * @returns calculate probability and return something like 50-70%
 */
export function calculateProbability(res) {
  let from = 1
  let to = 1
  for (let elem in res) {
    const prob = res[elem]
    from = from * (prob.from / 100)
    to = to * (prob.to / 100)
  }
  return { from, to }
}

/**
 *
 * @param {*} id
 * @param {*} tree
 * @param {*} node
 * @returns
 */

export function bfs(id, tree /* RawNodeDatum | RawNodeDatum[]*/, cb /* dispatch(removeid(id))*/) {
  const queue = []

  queue.unshift(tree)

  while (queue.length > 0) {
    const curNode = queue.pop()

    if (curNode.attributes?._id === id) return id

    const len = curNode.children.length

    for (let i = 0; i < len; i++) {
      queue.unshift(curNode.children[i])
    }
  }
  return false
}

export function bfsAndInsert(id, tree, node, { type }) {
  const queue = []
  queue.unshift(tree)

  while (queue.length > 0) {
    const curNode = queue.pop()

    if (curNode?.attributes?._id === id) {
      curNode?.children?.push(...node.values)
      curNode.childrenTree = {
        ...addChildrenTree({ name: node.name, type })
      }
      // TODO avoir les id et les renvoyer
      return { ...tree }
    }

    const len = curNode.children.length

    for (let i = 0; i < len; i++) {
      queue.unshift(curNode.children[i])
    }
  }
}

/**
 *
 * @param {*} {tree, all ids to track}
 * @param {*} callback
 * @returns
 */
// export function addNodeInEveryBranch({ tree, trackIds, payload }, callback) {
//   let newTree
//   trackIds.forEach((item) => {
//     // TODO add how to on every branch?
//     newTree = bfsAndInsert(item, copy, payload, { type: TREE_TYPES.EVENT })
//   })
//   return callback(newTree)
// }
