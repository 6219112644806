import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import { openEvent, openChoice } from '../Modals/modalSlice'
import {
  setCurrentNode,
  selectCurrentNode,
  updateTree,
  selectTrackIds,
  deleteTrackIds,
  addTrackId
} from './treeSlice'
import { bfs, goToTopNode, TREE_TYPES } from '../../lib/tree'
import {
  addBetweenBranch,
  chooseNumberOfForms,
  resetAddEverybranch,
  resetFormForUpdate,
  shouldAddFormForUpdate
} from '../Forms/formsSlice'

const classes = {
  select: 'text-gray-700 block px-4 py-2 text-sm'
}

export default function NodeWithChild({ hierarchyPointNode, nodeDatum }) {
  const dispatch = useDispatch()
  const currentNode = useSelector(selectCurrentNode)
  const trackIds = useSelector(selectTrackIds)

  if (hierarchyPointNode?.parent === null) {
    return <span className="circle-with-child bg-indigo-700 dropdown">-</span>
  }
  return (
    <span
      className="circle-with-child bg-indigo-700 dropdown"
      onMouseEnter={() => dispatch(setCurrentNode(hierarchyPointNode))}>
      -
      <div className="dropdown-content shadow-lg rounded-md border border-gray-300 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        <DeleteButton currentNode={currentNode} trackIds={trackIds} />

        <AddEvent
          currentNode={currentNode}
          // issueDefinitiveState={issueDefinitiveState}
          trackIds={trackIds}
        />
        <AddChoice
          currentNode={currentNode}
          // issueDefinitiveState={issueDefinitiveState}
          trackIds={trackIds}
        />
      </div>
    </span>
  )
}

function DeleteButton({ currentNode, trackIds }) {
  const dispatch = useDispatch()
  const onClick = () => {
    const text = 'Étes-vous certain de vouloir supprimer cette branche?'
    if (confirm(text)) {
      // let issueExist
      const copy = cloneDeep(currentNode)

      // find All the sub branches ids and delete theme from state
      const ids = trackIds.map((id) => bfs(id, copy.data)).filter(Boolean)
      dispatch(deleteTrackIds({ ids })) // delete ids that was deleted from the tree
      //reAdd Id of the parent, because it was previously deleted, when user adds children it deletes the parent id
      dispatch(addTrackId({ id: copy?.data?.attributes?._id }))
      delete copy.data.childrenTree
      copy.data.children.splice(0)
      const ret = goToTopNode(copy)
      dispatch(updateTree(ret.data))
      // if (bfs(issueInfo?._id, currentNode.data)) {
      //   dispatch(resetIssueInfosFromState())
      // }
    }
  }
  return (
    <div className={classes.select} onClick={() => onClick()}>
      Supprimer
    </div>
  )
}

function EditButton({ currentNode, trackIds }) {
  const dispatch = useDispatch()
  const onClick = () => {
    const copyTree = cloneDeep(currentNode)
    if (copyTree?.data?.childrenTree?.type === TREE_TYPES?.EVENT) dispatch(openEvent())
    if (copyTree?.data?.childrenTree?.type === TREE_TYPES?.CHOICE) dispatch(openChoice())
    // dispatch(openEvent())
    dispatch(chooseNumberOfForms({ numberOfForms: copyTree?.data?.children?.length }))
    dispatch(shouldAddFormForUpdate())
  }

  return (
    <div className={classes.select} onClick={() => onClick()}>
      Modifier
    </div>
  )
}

function AddEvent({ currentNode, trackIds }) {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(openEvent())
    dispatch(resetAddEverybranch())
    dispatch(resetFormForUpdate())

    dispatch(addBetweenBranch())
  }
  return (
    <div className={classes.select} onClick={() => onClick()}>
      Insérer un événement
    </div>
  )
}
function AddChoice({ currentNode, trackIds }) {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(openChoice())
    dispatch(resetAddEverybranch())
    dispatch(resetFormForUpdate())

    dispatch(addBetweenBranch())
  }
  return (
    <div className={classes.select} onClick={() => onClick()}>
      Insérer un choix
    </div>
  )
}
