import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import { useSelector, useDispatch } from 'react-redux'
import {
  openEvent,
  openChoice,
  selectEventModal,
  selectChoiceModal,
  selectIssueDefinitiveModal
} from './../Modals/modalSlice'

import ChoiceModal from '../Modals/Choice'
import EventModal from '../Modals/Event'
import IssueDefinitive from '../Modals/Issue'
import {
  chooseNumberOfForms,
  resetFormForUpdate,
  selectAddInEveryBranch,
  shouldAddInEveryBranch
} from '../Forms/formsSlice'
import { resetCurrentNode, selectTreeState } from '../Tree/treeSlice'
import { treeIsEmpty } from '../../lib/tree'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DropDown() {
  const [openModal, setOpenModal] = useState(false)
  const choiceModal = useSelector(selectChoiceModal)
  const eventModal = useSelector(selectEventModal)
  const issueModal = useSelector(selectIssueDefinitiveModal)
  const formState = useSelector(selectAddInEveryBranch)
  const treeState = useSelector(selectTreeState)

  const dispatch = useDispatch()

  return (
    <>
      <Menu as="div" className={'relative inline-block text-left px-3'}>
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            Evenement Général
            <ChevronUpIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="origin-top-right absolute right-0 -mt-[7.5rem] w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => {
                      dispatch(openEvent())
                      if (!treeIsEmpty(treeState)) {
                        dispatch(resetCurrentNode())
                        dispatch(shouldAddInEveryBranch())
                        dispatch(resetFormForUpdate())
                        dispatch(chooseNumberOfForms({ numberOfForms: 2 }))
                      }
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}>
                    Créer une éventualité
                  </div>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <p
                    href="#"
                    onClick={() => {
                      dispatch(openChoice())
                      if (!treeIsEmpty(treeState)) {
                        dispatch(chooseNumberOfForms({ numberOfForms: 2 }))
                        dispatch(resetCurrentNode())
                        dispatch(shouldAddInEveryBranch())
                        dispatch(resetFormForUpdate())
                      }
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}>
                    Créer un choix
                  </p>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <ChoiceModal isOpen={choiceModal} />
      <EventModal isOpen={eventModal} />
      <IssueDefinitive isOpen={issueModal} />
    </>
  )
}
