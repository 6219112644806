import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  numberOfForms: 2,
  addInEverybranch: false,
  addBetweenBranch: false,
  formForUpdate: false
}

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    chooseNumberOfForms: (state, { payload }) => {
      state.numberOfForms = payload.numberOfForms
    },
    resetNumberOfForms: (state) => {
      state.numberOfForms = 2
    },
    shouldAddInEveryBranch: (state) => {
      state.addInEverybranch = true
    },
    resetAddEverybranch: (state) => {
      state.addInEverybranch = false
    },
    // TODO add form for update
    shouldAddFormForUpdate: (state) => {
      state.formForUpdate = true
    },
    resetFormForUpdate: (state) => {
      state.formForUpdate = false
    },
    addBetweenBranch: (state) => {
      state.addBetweenBranch = true
    },
    resetAddBetweenBranch: (state) => {
      state.addBetweenBranch = false
    }
  }
})

export const {
  chooseNumberOfForms,
  resetNumberOfForms,
  shouldAddInEveryBranch,
  resetAddEverybranch,
  shouldAddFormForUpdate,
  resetFormForUpdate,
  addBetweenBranch,
  resetAddBetweenBranch
} = formsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectTree = (state) => state.tree.tree
export const selectNumberOfForms = (state) => state.forms.numberOfForms
export const selectAddInEveryBranch = (state) => state.forms.addInEverybranch
export const selectFormForUpdate = (state) => state.forms.formForUpdate

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState())
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount))
//   }
// }

export default formsSlice.reducer
