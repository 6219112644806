import React from 'react'

const Introduction = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[60vh]">
      <h3 className="text-4xl font-normal leading-normal mt-0 mb-2 text-indigo-800">What if</h3>
      <p className="pt-2 italic">Simulateur de décision en situation d’incertitude</p>
      <p className="pt-4 pb-1">Pour commencer, cliquez sur “évènement général” et choisissez </p>
      <ul className="list-none list-inside pt-1" role="list">
        <li>
          - une “éventualité” (c’est-à-dire un évènement que vous ne contrôlez pas et dont vous
          devez estimer les probabilités) ou
        </li>
        <li>- un “choix” (c’est-à-dire un évènement que vous contrôlez personnellement).</li>
      </ul>
    </div>
  )
}

export default Introduction
