import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { chooseNumberOfForms, selectNumberOfForms } from '../Forms/formsSlice'
const chooseNumbers = [
  { id: 'two', title: 'Deux', number: 2 },
  { id: 'three', title: 'Trois', number: 3 },
  { id: 'four', title: 'Quatre', number: 4 },
  { id: 'five', title: 'Cinq', number: 5 }
]

export default function RadioButtons() {
  const dispatch = useDispatch()
  const numberOfForms = useSelector(selectNumberOfForms)
  return (
    <div>
      <label className="text-base font-medium text-gray-900">Choisissez le nombre</label>
      {/* <p className="text-sm leading-5 text-gray-500">How do you prefer to receive notifications?</p> */}
      <fieldset className="mt-4">
        <legend className="sr-only">Choisissez un numéro</legend>
        <div className="flex justify-center space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
          {chooseNumbers.map((number) => (
            <div key={number.id} className="flex items-center">
              <input
                id={number.id}
                name="choose-number"
                type="radio"
                defaultChecked={number?.number === numberOfForms || number?.id === 'two'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                onClick={() => dispatch(chooseNumberOfForms({ numberOfForms: number.number }))}
              />
              <label htmlFor={number.id} className="ml-3 block text-sm font-medium text-gray-700">
                {number.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}
