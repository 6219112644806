import { PencilAltIcon } from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goToTopNode } from '../../lib/tree'
import { shouldAddFormForUpdate } from '../Forms/formsSlice'
import { openIssueDefinitive } from '../Modals/modalSlice'
import ProbabilityResult from './ProbabilityResult'
import {
  setCurrentNode,
  selectCurrentNode,
  updateTree,
  addTrackId,
  addTrackIdAfterIssueDelete,
  resetCurrentNode
} from './treeSlice'

export function IssueNodeFrame({ nodeDatum, hierarchyPointNode }) {
  const [hover, setHover] = React.useState(false)
  const [width, setWidth] = React.useState(false)
  const [height, setHeight] = React.useState(false)
  const hoverClass =
    'flex justify-center flex-col items-center p-2  group font-sans font-normal box-border border-1 rounded-lg  text-white'
  const dispatch = useDispatch()
  const currentNode = useSelector(selectCurrentNode)
  const onClick = () => {
    dispatch(openIssueDefinitive())
    dispatch(shouldAddFormForUpdate())
  }
  return (
    <div
      className={
        hover
          ? hoverClass
          : 'flex justify-center flex-col items-center py-2 truncate whitespace-normal'
      }
      style={{
        marginTop: '35px',
        borderRadius: '5px',
        backgroundColor: hover ? '#36454F' : '#dedede',
        overflow: 'unset',
        position: 'relative',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem'
      }}
      onMouseEnter={(e) => {
        // console.log({ e, w: e.target.clientWidth, h: e.target.offsetHeight })

        setHover(true)
        setWidth(e.target.clientWidth)
        setHeight(e.target.offsetHeight)
        dispatch(setCurrentNode(hierarchyPointNode))
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      onClick={() => onClick()}>
      <>
        {hover ? (
          <>
            <p className="invisible"> {nodeDatum?.name} </p>
            <p className="flex flex-row">
              <PencilAltIcon className="mr-1 h-5 w-5 text-white" aria-hidden="true" />
              modifier
            </p>
            <p className="invisible">{nodeDatum?.comment} </p>
          </>
        ) : (
          <>
            <p className="text-center">{nodeDatum?.name}</p>
            <ProbabilityResult hierarchyPointNode={hierarchyPointNode} />
            <p className="text-center"> {nodeDatum?.comment} </p>
          </>
        )}
      </>
    </div>
  )
}

export default function IssueNode({ hierarchyPointNode }) {
  const dispatch = useDispatch()
  const currentNode = useSelector(selectCurrentNode)
  return (
    <span className="circle-issue dropdown bg-slate-700">
      -
      <div
        className="dropdown-content shadow-lg rounded-md border border-gray-300 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        onMouseEnter={() => {
          dispatch(setCurrentNode(hierarchyPointNode))
        }}>
        <div
          onClick={() => {
            const text = 'Étes-vous certain de vouloir supprimer cette issue?'
            if (confirm(text)) {
              const copy = cloneDeep(currentNode)
              const id = copy?.data?.attributes?._id
              const parentId = copy?.parent?.data?.attributes?._id
              copy?.parent?.data?.children?.splice(0)
              const ret = goToTopNode(copy)
              dispatch(updateTree(ret.data))
              dispatch(addTrackIdAfterIssueDelete({ id: parentId }))
            }
          }}>
          Supprimer
        </div>
      </div>
    </span>
  )
}
