import { configureStore } from '@reduxjs/toolkit'
import treeReducer from '../features/Tree/treeSlice'
import modalReducer from './../features/Modals/modalSlice'
import formReducer from '../features/Forms/formsSlice'
export const store = configureStore({
  reducer: {
    tree: treeReducer,
    probabilityModal: modalReducer,
    forms: formReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['tree/setCurrentNode'],
        ignoredActionPaths: ['tree.currentNode'],
        ignoredPaths: ['tree.currentNode']
      }
    })
})
