import fileDownload from 'js-file-download'
import cloneDeep from 'lodash.clonedeep'

function dateNormalizer(dateObj = new Date()) {
  const month = dateObj.getUTCMonth() + 1 //months from 1-12
  const day = dateObj.getUTCDate()
  const year = dateObj.getUTCFullYear()
  return year + '-' + month + '-' + day
}
export async function download(tree, lastIds) {
  const file = {}
  const treeCopy = cloneDeep(tree)
  file.tree = treeCopy
  const stringified = JSON.stringify(file)
  const date = dateNormalizer()
  fileDownload(stringified, `${date}_tree.txt`, { indent: 4 })
}
