import React, { useState, useEffect } from 'react'
import Tree from 'react-d3-tree'
import { selectTree, selectVisibility } from './treeSlice'
import { useSelector /*useDispatch*/ } from 'react-redux'
import { renderForeignObjectNode } from './NodeLabel'
import './tree.css'
const containerStyles = {
  width: '100vw',
  height: '100vh'
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default function TreeComponent() {
  // eslint-disable-next-line
  // const [visibility, setVisibility] = useState('hidden')
  const visibility = useSelector(selectVisibility)

  const tree = useSelector(selectTree)
  const { height, width } = getWindowDimensions()

  const onNodeClick = (node) => {
    // setCurrentNode((prev) => node)
    // setShow(true)
  }
  const nodeSize = { x: 200, y: 200 }

  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    position: 'relative',
    overflow: 'unset',
    x: -100,
    y: -20
  }

  return (
    // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
    <>
      <div
        id="treeWrapper"
        className={`treeContainer ${visibility}`}
        style={{ width: '100vw', height: '90vh' }}>
        <Tree
          data={tree}
          translate={{ x: width / 2, y: height / 10 }}
          nodeSize={nodeSize}
          orientation="vertical"
          collapsible={false}
          pathFunc={straightPathFunc}
          separation={{ nonSiblings: 2, siblings: 1.7 }}
          onNodeClick={(node) => {
            console.log({ node }, 'clicked')
          }}
          renderCustomNodeElement={(rd3props) =>
            renderForeignObjectNode({ ...rd3props, onNodeClick, foreignObjectProps })
          }
          rootNodeClassName="node__root"
          branchNodeClassName="node__branch"
          leafNodeClassName="node__leaf"
          pathClassFunc={() => 'node__link'}
        />
      </div>
      {/* <AiOutlinePlusCircle /> */}
    </>
  )
}

const straightPathFunc = (linkDatum, orientation) => {
  const { source, target } = linkDatum
  return orientation === 'horizontal'
    ? `M${source.y},${source.x}L${target.y},${target.x}`
    : `M${source.x},${source.y}L${target.x},${target.y}`
}

// Here we're using `renderCustomNodeElement` to represent each node
// as an SVG `rect` instead of the default `circle`.
let circleClasses = 'inline-block p-7 rounded-full w-20 mx-auto'
let iconStyles = { color: 'white', fontSize: '1.5em' }
// const renderRectSvgNode = ({
//   nodeDatum,
//   onNodeClick,
//   hierarchyPointNode,
//   foreignObjectProps,
//   toggleNode
// }) => (
//   <g>
//     {/* <rect width="20" height="20" x="-10" onClick={() => console.log(nodeDatum)} /> */}
//     {/* <AiOutlinePlusCircle width="30" height="30" x="-10" onClick={() => console.log(nodeDatum)} /> */}
//     {nodeDatum?.type === 'ISSUE' ? (
//       <BsDashCircle
//         // onClick={() => onNodeClick(hierarchyPointNode)}
//         style={{
//           position: 'absolute',
//           top: '20px',
//           right: '20px'
//         }}
//         size="40px"
//         // color="white"
//         x="-20"
//         y="-20"
//       />
//     ) : nodeDatum?.type === 'CHOICE' ? (
//       <AiFillCheckCircle
//         onClick={() => onNodeClick(hierarchyPointNode)}
//         style={{
//           position: 'absolute',
//           top: '20px',
//           right: '20px'
//         }}
//         size="50px"
//         // color="white"
//         x="-20"
//         y="-20"
//       />
//     ) : (
//       <AiOutlinePlusCircle
//         onClick={() => onNodeClick(hierarchyPointNode)}
//         style={{
//           position: 'absolute',
//           top: '20px',
//           right: '20px',
//           color: 'black',
//           backgroundColor: 'red',
//           stroke: 'black',
//           fill: 'black',
//           // fillOpacity: 0,
//           strokeOpacity: 0
//         }}
//         size="50px"
//         color="black"
//         x="-25"
//         y="-25"
//       />
//     )}
//     {/* <text fill="black" strokeWidth="1" x="20">
//       {nodeDatum.name}
//     </text>
//     {nodeDatum.attributes?.department && (
//       <text fill="black" x="20" dy="20" strokeWidth="1">
//         Department: {nodeDatum.attributes?.id}
//       </text>
//     )} */}

//     <foreignObject {...foreignObjectProps}>
//       <div
//         style={{
//           border: '1px solid transparent',
//           borderRadius: '5px',
//           backgroundColor: '#dedede'
//         }}>
//         <h3 style={{ textAlign: 'center' }}>{nodeDatum.name}</h3>
//         {nodeDatum.children && (
//           <button
//             style={{
//               width: '75%',
//               margin: '0 auto 1rem auto',
//               display: 'block'
//             }}
//             onClick={toggleNode}>
//             {nodeDatum.__rd3t.collapsed ? 'Expand' : 'Collapse'}
//           </button>
//         )}
//       </div>
//     </foreignObject>
//   </g>
// )
