import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  chooseNumberOfForms,
  resetAddBetweenBranch,
  // addInEverybranch,
  resetAddEverybranch,
  resetFormForUpdate,
  selectAddInEveryBranch,
  selectFormForUpdate,
  selectNumberOfForms
} from './formsSlice'
import {
  deleteTrackId,
  firstUpdateToTree,
  selectCurrentNode,
  selectTree,
  selectTreeState,
  addTrackIds,
  updateTree,
  selectTrackIds,
  emptyTrackIds,
  resetCurrentNode,
  falsifyFirstUpdate
} from './../Tree/treeSlice'
import { initialValue, giveNumberOfFormToValidate } from './ChoiceFormikFns'

import { addAttrAndIds, extractFormValues, FIELD_REQUIRED_ERROR } from './../../lib/formik'

import { closeChoice } from '../Modals/modalSlice'
import RadioButtons from './../Modals/RadioButtons'
import {
  addChildrenTree,
  bfsAndInsert,
  goToTopNode,
  TREE_TYPES,
  topOfHierarchy
} from '../../lib/tree'
import cloneDeep from 'lodash.clonedeep'

const FORM_NAMES = ['formOne', 'formTwo', 'formThree', 'formFour', 'formFive']
const classes = {
  input: 'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm'
}
function ChoiceNameForm({ formik }) {
  return (
    <>
      <div className="mb-4 border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
        <label htmlFor="name" className="block text-xs font-medium text-gray-900">
          Nom
        </label>
        <input
          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
          type="text"
          name="choice.name"
          value={formik.values.choice.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder="nom du choix"
        />
        {formik?.touched?.choice && formik?.errors?.choice && (
          <div style={{ color: 'red' }}>
            <small>{formik.errors.choice.name}</small>
          </div>
        )}
      </div>
    </>
  )
}

function epmtyInitValues() {
  return { name: '' }
}

export default function MainForm({ children }) {
  const [formError, setFormError] = useState('')
  const [choiceNameError, setChoiceNameError] = useState('')

  const numberOfForms = useSelector(selectNumberOfForms)
  const treeState = useSelector(selectTreeState)
  const currentNode = useSelector(selectCurrentNode)
  const trackIds = useSelector(selectTrackIds)
  const tree = useSelector(selectTree)
  const addInEveryBranch = useSelector(selectAddInEveryBranch)
  const formForUpdate = useSelector(selectFormForUpdate)
  const addBetweenBranch = useSelector((state) => state.forms.addBetweenBranch)

  const dispatch = useDispatch()

  const updateSubmit = ({ payload, values }) => {
    const copyTree = cloneDeep(currentNode)
    if (!topOfHierarchy(copyTree)) copyTree.data.childrenTree.name = payload?.name
    if (topOfHierarchy(copyTree)) copyTree.data.name = payload?.name

    const forms = extractFormValues(values, numberOfForms, { ignore: 'choice' })
    forms.forEach((elem, index) => {
      copyTree.data.children[index].name = payload?.values[index]?.name
    })

    const ret = goToTopNode(copyTree)
    dispatch(updateTree(ret?.data))
    dispatch(resetFormForUpdate())
    dispatch(closeChoice())
    dispatch(resetCurrentNode())
  }

  const addInBetweenBranchSubmit = ({ values, payload }) => {
    const copyTree = cloneDeep(currentNode)
    let tempStockChildrens
    if (copyTree?.data?.children) tempStockChildrens = [...copyTree.data.children]
    const tempStockChildrenTree = { ...copyTree?.data?.childrenTree }
    copyTree.data.childrenTree = {
      name: payload?.name,
      type: TREE_TYPES?.CHOICE
    }
    copyTree?.data?.children.splice(0)
    payload.values[0].childrenTree = {
      ...tempStockChildrenTree
    }
    const trackIds = payload?.ids.filter((id) => id !== payload?.values[0]?.attributes?._id)
    payload.values[0].children.push(...tempStockChildrens)
    copyTree?.data?.children.push(...payload.values)
    const ret = goToTopNode(copyTree)
    dispatch(updateTree(ret.data))
    dispatch(addTrackIds(trackIds))
    dispatch(resetFormForUpdate())
    dispatch(closeChoice())
    dispatch(resetCurrentNode())
    dispatch(resetAddBetweenBranch())
    dispatch(chooseNumberOfForms({ numberOfForms: 2 }))
  }

  const getChoiceName = (node) => {
    if (addBetweenBranch) return { ...epmtyInitValues }
    if (topOfHierarchy(node)) return { name: node?.data?.name }
    return { name: currentNode?.data?.childrenTree?.name || '' }
  }
  const formik = useFormik({
    initialValues: {
      choice: getChoiceName(currentNode),
      formOne: addBetweenBranch
        ? { ...epmtyInitValues }
        : { ...initialValue(currentNode?.data?.children[0]) },

      formTwo: addBetweenBranch
        ? { ...epmtyInitValues }
        : { ...initialValue(currentNode?.data?.children[1]) },
      formThree: addBetweenBranch
        ? { ...epmtyInitValues }
        : { ...initialValue(currentNode?.data?.children[2]) },
      formFour: addBetweenBranch
        ? { ...epmtyInitValues }
        : { ...initialValue(currentNode?.data?.children[3]) },
      formFive: addBetweenBranch
        ? { ...epmtyInitValues }
        : { ...initialValue(currentNode?.data?.children[4]) }
    },
    validationSchema: Yup.object({
      ...giveNumberOfFormToValidate(numberOfForms),
      choice: Yup.object({
        name: Yup.string().min(1, 'Le nom saisi est trop court.').required(FIELD_REQUIRED_ERROR)
      })
    }),
    onSubmit: (values, submitHandler) => {
      const res = extractFormValues(values, numberOfForms, { ignore: 'choice' })
      const forms = addAttrAndIds(res, { TYPE: TREE_TYPES.CHOICE })
      const payload = { name: values.choice.name, values: forms.addedFroms, ids: forms.ids }

      if (treeState.FIRST_UPDATE === true) {
        dispatch(firstUpdateToTree({ ...payload, type: TREE_TYPES?.CHOICE }))
        dispatch(falsifyFirstUpdate())
        dispatch(addTrackIds(payload.ids))
        dispatch(closeChoice())
        return
      }
      if (formForUpdate === true) {
        updateSubmit({ payload, values })
        return
      }
      if (addInEveryBranch === true) {
        let formValues = extractFormValues(values, numberOfForms, { ignore: 'choice' })
        const newIds = []
        const treeCopy = cloneDeep(tree)
        let newTree
        trackIds.forEach((elem) => {
          let forms = addAttrAndIds(formValues, { TYPE: TREE_TYPES.CHOICE })
          // formValues = addEstimation(forms.addedFroms)
          const payload = { name: values.choice.name, values: forms.addedFroms, ids: forms.ids }
          newIds.push(...payload.ids)
          newTree = bfsAndInsert(elem, treeCopy, payload, { type: TREE_TYPES.CHOICE })
        })
        dispatch(emptyTrackIds())
        dispatch(addTrackIds(newIds))
        dispatch(updateTree(newTree))
        dispatch(resetAddEverybranch())

        dispatch(closeChoice())
        return
      }

      if (addBetweenBranch === true) {
        addInBetweenBranchSubmit({ values, payload })
        return
      }
      // normal // add to one branch
      currentNode.data.children.push(...payload.values)
      currentNode.data.childrenTree = {
        ...addChildrenTree({ name: payload.name, type: TREE_TYPES.CHOICE })
      }
      const ret = goToTopNode(currentNode)
      dispatch(updateTree(ret.data))
      dispatch(deleteTrackId({ id: currentNode?.data?.attributes?._id }))
      dispatch(addTrackIds(payload.ids))
      dispatch(closeChoice())
    }
  })

  return (
    <>
      {formError && (
        <div className="flex justify-center py-3 " style={{ color: 'red' }}>
          <small>{formError}</small>
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        {choiceNameError && (
          <div className="flex justify-center py-3 " style={{ color: 'red' }}>
            <small>{choiceNameError}</small>
          </div>
        )}
        <ChoiceNameForm formik={formik} />
        <div className="py-4 sm:py-5 sm:grid  sm:gap-4 sm:px-6">
          {!formForUpdate && <RadioButtons />}
        </div>
        {[...Array(numberOfForms)].map((x, idx) => (
          <Form key={FORM_NAMES[idx]} formik={formik} formName={FORM_NAMES[idx]} />
        ))}
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          {children}
        </div>
      </form>
    </>
  )
}

function Form({ formik, formName }) {
  return (
    <div className="border border-gray-300 rounded-md px-3 py-2 mb-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label htmlFor={formName.name} className="block text-xs font-medium text-gray-900">
        Nom
      </label>
      <Name formik={formik} formName={formName} name={`${formName}.name`} />
    </div>
  )
}

function Name({ formik, name, formName }) {
  return (
    <>
      <input
        type="text"
        name={name}
        value={formik.values[`${formName}`].name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        className={classes.input}
        placeholder="choix"
      />
      {formik.touched?.[`${formName}`] && formik?.errors?.[`${formName}`] && (
        <div style={{ color: 'red' }}>
          <small>{formik?.errors[`${formName}`]?.name}</small>
        </div>
      )}
    </>
  )
}
