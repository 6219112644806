import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import downloadjs from 'downloadjs'
import { toPng } from 'html-to-image'

import {
  selectTree,
  updateTree,
  falsifyFirstUpdate,
  firstUpdateToTree,
  firstUpdateToTreeFromFile,
  selectTreeState,
  addTrackIds,
  addIssueInfosToState
} from './../Tree/treeSlice'
import { download } from '../../lib/download'

import DropDown from './DropDownBtn'
import { treeIsEmpty } from '../../lib/tree'

const classes = {
  btn: 'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'
}

function DownloadData({ myTree }) {
  const tree = useSelector(selectTree)
  const treeState = useSelector(selectTreeState)
  const onClick = () => {
    if (treeIsEmpty(treeState)) {
      alert('Aucune donnée correspondante trouvée')
      return
    }
    return download({ tree, trackIds: treeState.trackIds })
  }
  return (
    <div className="relative inline-block text-left px-3">
      <button className={classes.btn} onClick={onClick}>
        Télécharger les données
      </button>
    </div>
  )
}
function ImageMaker({ myTree }) {
  const tree = useSelector(selectTree)
  const treeState = useSelector(selectTreeState)
  const onClick = () => {
    if (treeIsEmpty(treeState)) {
      // eslint-disable-next-line quotes
      alert('Aucune donnée correspondante trouvée pour télécharger')
      return
    }
    const id = document.getElementById('treeWrapper')
    toPng(id, {})
      .then((dataUrl) => downloadjs(dataUrl, 'my-node.png'))
      .catch((error) => {
        alert('une erreur est survenue lors du telechargement')
      })
  }
  return (
    <div className="relative inline-block text-left px-3">
      <button className={classes.btn} onClick={onClick}>
        Enregistrer l&apos;image
      </button>
    </div>
  )
}

function UploadFile() {
  const tree = useSelector(selectTree)
  const treeState = useSelector(selectTreeState)
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = React.useState(null)
  const [isSelected, setIsSelected] = React.useState(null)
  const fileRef = React.useRef()
  const getFileExtension = (filename) => {
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length)
    return extension
  }
  React.useEffect(() => {
    const reader = new FileReader()
    // reader.onload = async (e) => {
    //   const text = e.target.result
    //   alert(text)
    // }
    // reader.readAsText(e.target.files[0])
  }, [selectedFile])
  const handlerIfTreeExists = () => {
    if (!treeIsEmpty(treeState)) {
      alert('Vous ne pouvez plus charger les données')
    }
  }
  const changeHandler = (e) => {
    if (getFileExtension(e.target.files[0].name) !== 'txt') {
      e.target.value = null
      // eslint-disable-next-line quotes
      alert("ce type de fichier n'est pas pris en charge")
      return
    }
    setSelectedFile(e.target.files[0])
    setIsSelected(true)

    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = e.target.result
      const parse = JSON.parse(text)
      dispatch(firstUpdateToTreeFromFile(parse.tree.tree))
      dispatch(addTrackIds(parse.tree.trackIds))
      dispatch(falsifyFirstUpdate())
    }
    reader.readAsText(e.target.files[0])
  }
  return (
    <>
      <div className="relative inline-block text-left px-3">
        {/* <button
          className={classes.btn}
          onClick={() => {
            console.log('clicked upload')
          }}>
          Uploadez le fichier
        </button> */}

        <form name="uploadForm">
          <div>
            {treeIsEmpty(treeState) && (
              <input
                id="tree-file"
                type="file"
                name="tree-file"
                onChange={(e) => {
                  if (!treeIsEmpty(treeState)) {
                    alert('Une erreur est survenue')
                    return
                  }
                  changeHandler(e)
                }}
                title="jiad"
                style={{ display: 'none' }}
                className="hidden"
              />
            )}
            <label
              htmlFor="tree-file"
              className={`${classes.btn} cursor-pointer`}
              onClick={handlerIfTreeExists}>
              Charger les données
            </label>
            {/* selected files: <span id="fileNum">0</span>; total size: <span id="fileSize">0</span> */}
          </div>
          <div>{/* <input type="submit" value="Send file" /> */}</div>
        </form>
      </div>
    </>
  )
}

export default function MenuBar() {
  const tree = useSelector(selectTree)
  return (
    <>
      <DropDown />
      <DownloadData myTree={tree} />
      <UploadFile myTree={tree} />
      <ImageMaker myTree={tree} />
    </>
  )
}
