/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { openEvent, closeEvent } from './modalSlice'
import { useDispatch } from 'react-redux'
import {
  selectNumberOfForms,
  chooseNumberOfForms,
  resetFormForUpdate,
  resetAddEverybranch,
  resetAddBetweenBranch
} from '../Forms/formsSlice'
import Forms from '../Forms/EventForms'
import { useSelector } from 'react-redux'
import { selectFormForUpdate } from './../Forms/formsSlice'
import { resetCurrentNode } from '../Tree/treeSlice'

/**
 *
 * COMPONENT FOR EVENT MODAL
 *
 */
const formNames = ['formOne', 'formTwo', 'formThree', 'formFour', 'formFive']

export default function EventModal({ isOpen }) {
  const numberOfForms = useSelector(selectNumberOfForms)
  const dispatch = useDispatch()
  const formForUpdate = useSelector(selectFormForUpdate)

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(openEvent())}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {formForUpdate ? 'Mettre à jour' : 'Créer une éventualité'}
                  </Dialog.Title>
                  {/* <div className="mt-2 text-center sm:mt-5">
                    {!formForUpdate && <RadioButtons />}
                  </div> */}
                  <div className="mt-2">
                    <p className="text-sm text-gray-500"></p>
                    {/* ------------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------------ */}
                    {/* <form className="App" onSubmit={formik.handleSubmit}> */}
                    {/* <Form formik={formik} formName="formOne" />
                      <Form formik={formik} formName="formTwo" />
                      <Form formik={formik} formName="formThree" />
                      <Form formik={formik} formName="formFour" />
                      <Form formik={formik} formName="formFive" /> */}
                    {/* {[...Array(numberOfForms)].map((x, idx) => (
                        <Form key={formNames[idx]} formik={formik} formName={formNames[idx]} />
                      ))} */}
                    {/* <button type="submit">Submit</button> */}
                    {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                          onClick={() => {
                            // dispatch(closeEvent())
                          }}>
                          Submit
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                          onClick={() => {
                            dispatch(closeEvent())
                          }}
                          ref={cancelButtonRef}>
                          Cancel
                        </button>
                      </div>
                    </form> */}
                    {/* ------------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------------ */}
                    {/* ------------------------------------------------------------------------ */}
                    {/* <Forms formik={formik}> */}
                    <Forms>
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                          // dispatch(closeEvent())
                        }}
                        ref={cancelButtonRef}>
                        {formForUpdate ? 'Mettre à jour' : 'Créer une éventualité'}
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {
                          dispatch(chooseNumberOfForms({ numberOfForms: 2 }))
                          dispatch(resetFormForUpdate())
                          dispatch(resetAddEverybranch())
                          dispatch(closeEvent())
                          dispatch(resetCurrentNode())
                          dispatch(resetAddBetweenBranch())
                        }}
                        ref={cancelButtonRef}>
                        Annuler
                      </button>
                    </Forms>
                  </div>
                </div>
              </div>
              {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    dispatch(closeEvent())
                  }}>
                  Deactivate
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    dispatch(closeEvent())
                  }}
                  ref={cancelButtonRef}>
                  Cancel
                </button>
              </div> */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
