import { v4 as uuidv4 } from 'uuid'

export const FIELD_REQUIRED_ERROR = 'Ce champ est requis'

/**
 *
 * @param {formik} returns all forms keys, not the event form and
 * @returns
 */

export function getAllFormsKeys(formikValues, numberOfForms, ignore) {
  return Object.keys(formikValues)
    .filter((str) => str !== ignore)
    .slice(0, numberOfForms)
}

/**
 *
 * @param {*} formikValues
 * @param {*} numberOfForms
 * @returns give forms objects inside an array, so we can push an array directly inside the hierarchy children
 */
export function extractFormValues(formikValues, numberOfForms, { ignore }) {
  const res = getAllFormsKeys(formikValues, numberOfForms, ignore)
  return res.map((form) => formikValues[form])
}

/**
 *
 * @param {*} forms, {TYPE: EVENT | CHOICE | ISSUE DEFINITIVE}
 * @returns object {forms and IDS to keep track of last added ids}
 */
export function addAttrAndIds(forms, { TYPE } = {}) {
  let ids = []
  const addedFroms = forms.map((form) => {
    let _id = uuidv4()
    ids.push(_id)
    return { ...form, attributes: { _id, type: TYPE }, children: [] }
  })
  return { addedFroms, ids }
}
