import cloneDeep from 'lodash.clonedeep'
import React from 'react'
import { calculateProbability, TREE_TYPES } from '../../lib/tree'

export default function ProbabilityResult({ hierarchyPointNode }) {
  const [result, setResult] = React.useState({ from: 0, to: 0 })
  const calculateResult = () => {
    let probabilities = []
    const copy = cloneDeep(hierarchyPointNode)
    let loop = copy.parent
    while (loop) {
      if (Object.entries(loop).length === 0) break
      if (loop.data.attributes?.type == TREE_TYPES.EVENT) {
        const from = loop?.data?.attributes?.from
        const to = loop?.data?.attributes?.to
        probabilities.push({ from, to })
      }
      loop = loop.parent
    }
    return probabilities
  }

  React.useEffect(() => {
    const arrayOfProbabilities = calculateResult()
    const res = calculateProbability(arrayOfProbabilities)

    setResult((prev) => ({ from: res.from * 100, to: res.to * 100 }))
  })
  return (
    <p className="font-medium text-center">{`${result.from.toFixed(2)}% - ${result.to.toFixed(
      2
    )}%`}</p>
  )
}
