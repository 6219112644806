import React from 'react'
import { useDispatch } from 'react-redux'
import { resetFormForUpdate } from '../Forms/formsSlice'
import { openIssueDefinitive, openEvent, openChoice } from '../Modals/modalSlice'
import { setCurrentNode } from './treeSlice'

export default function ChildlessNode({ hierarchyPointNode }) {
  const dispatch = useDispatch()
  return (
    <>
      <span className="circle_no_child dropdown bg-indigo-700">
        +
        <div className="dropdown-content shadow-lg rounded-md border border-gray-300 px-1 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <div
            onClick={() => {
              // dispatch()
              dispatch(openEvent())
              dispatch(resetFormForUpdate())
              dispatch(setCurrentNode(hierarchyPointNode))
            }}>
            Créer une éventualité
          </div>
          <div
            onClick={() => {
              // dispatch()
              dispatch(openChoice())
              dispatch(resetFormForUpdate())
              dispatch(setCurrentNode(hierarchyPointNode))
            }}>
            Créer un choix
          </div>
          <div
            onClick={() => {
              // if (issueState.isEmpty === false) {
              //   // eslint-disable-next-line quotes
              //   alert("Une issue definitive existe déja sur l'arbre")
              //   return
              // }
              dispatch(openIssueDefinitive())
              dispatch(resetFormForUpdate())
              dispatch(setCurrentNode(hierarchyPointNode))
            }}>
            Créer une issue définitive
          </div>
        </div>
      </span>
    </>
  )
}
