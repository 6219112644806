import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { FIELD_REQUIRED_ERROR } from '../../lib/formik'

export function initialValue(data) {
  return { name: data?.name || '' }
}

export function validateSchema() {
  return {
    name: Yup.string().required(FIELD_REQUIRED_ERROR).min(1, 'Le nom saisi est trop court')
  }
}

export const firstTwoFormValidation = {
  formOne: Yup.object(validateSchema()),
  formTwo: Yup.object(validateSchema())
}

export function giveNumberOfFormToValidate(num) {
  if (num === 2) return { ...firstTwoFormValidation }
  if (num === 3)
    return {
      ...firstTwoFormValidation,
      formThree: Yup.object(validateSchema())
    }
  if (num === 4)
    return {
      ...firstTwoFormValidation,
      formThree: Yup.object(validateSchema()),
      formFour: Yup.object(validateSchema())
    }
  if (num === 5)
    return {
      ...firstTwoFormValidation,
      formThree: Yup.object(validateSchema()),
      formFour: Yup.object(validateSchema()),
      formFive: Yup.object(validateSchema())
    }
}

/**
 *
 * @param {formik} returns all forms keys, not the event form and
 * @returns
 */

export function getAllFormsKeys(formik, numberOfForms) {
  return Object.keys(formik)
    .filter((str) => str !== 'choice')
    .slice(0, numberOfForms)
}

/**
 *
 * @param {*} formik
 * @param {*} numberOfForms
 * @returns get only forms that was typed by user
 */
export function giveOnlySelectedFormInputs(formik, numberOfForms) {
  const res = {}
  getAllFormsKeys(formik, numberOfForms).forEach((key) => (res[`${key}`] = formik.values[`${key}`]))
  return res
}

/**
 *
 * @param {*} formikValues
 * @param {*} numberOfForms
 * @returns give forms objects inside an array, so we can push an array directly inside the hierarchy children
 */
// export function exractFromValues(formikValues, numberOfForms) {
//   const res = getAllFormsKeys(formikValues, numberOfForms)
//   return res.map((form) => formikValues[form])
// }

/**
 *
 * @param {*} forms
 * @returns object {forms and IDS to keep track of last added ids}
 */
export function addAttrAndIds(forms) {
  let ids = []
  const addedFroms = forms.map((form) => {
    let _id = uuidv4()
    ids.push(_id)
    return { ...form, attributes: { _id, type: 'CHOICE' }, children: [] }
  })
  return { addedFroms, ids }
}

/**
 *
 * @param {*} formik
 * @param {*} numberOfForms
 * @returns get only forms that was typed by user
 */
export function formHaveErrors(formik) {
  return Object.keys(formik.errors).length !== 0
}
