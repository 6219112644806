import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { FIELD_REQUIRED_ERROR } from '../../lib/formik'
/**
 *
 */
export const event = {
  event: Yup.object(
    Yup.string().min(1, 'Le nom saisi est trop court').required(FIELD_REQUIRED_ERROR)
  )
}

export function validateSchema() {
  return {
    name: Yup.string().required(FIELD_REQUIRED_ERROR),
    probability: Yup.string().required(FIELD_REQUIRED_ERROR),
    ecartType: Yup.string().required(FIELD_REQUIRED_ERROR)
  }
}

export const firstTwoFormValidation = {
  formOne: Yup.object(validateSchema()),
  formTwo: Yup.object(validateSchema())
}
export const othersFormsForValidation = {
  formThree: Yup.object(validateSchema()),
  formFour: Yup.object(validateSchema())
}

export function giveNumberOfFormToValidate(num) {
  if (num === 2) return { ...firstTwoFormValidation }
  if (num === 3)
    return {
      ...firstTwoFormValidation,
      formThree: Yup.object(validateSchema())
    }
  if (num === 4)
    return {
      ...firstTwoFormValidation,
      formThree: Yup.object(validateSchema()),
      formFour: Yup.object(validateSchema())
    }
  if (num === 5)
    return {
      ...firstTwoFormValidation,
      formThree: Yup.object(validateSchema()),
      formFour: Yup.object(validateSchema()),
      formFive: Yup.object(validateSchema())
    }
}

export function initialValue(data) {
  return {
    name: data?.name || '',
    probability: data?.probability || '',
    ecartType: data?.ecartType || ''
  }
}

/**
 *
 * @param {formik} returns all forms keys, not the event form and
 * @returns
 */

export function getAllFormsKeys(formikValues, numberOfForms) {
  return Object.keys(formikValues)
    .filter((str) => str !== 'event')
    .slice(0, numberOfForms)
}
/**
 *
 * @param {} formik, numberoFforms(redux)
 * @returns Boolean if all forms have a probability greater then 0
 */
export function allFormsHaveProbability(formikValues, numberOfForms) {
  return getAllFormsKeys(formikValues, numberOfForms).every(
    (form) => Number(formikValues[`${form}`].probability) > 0
  )
}

/**
 *
 * @param {*} formikValues
 * @param {*} numberOfForms
 * @returns give forms objects inside an array, so we can push an array directly inside the hierarchy children
 */
// export function exractFromValues(formikValues, numberOfForms) {
//   const res = getAllFormsKeys(formikValues, numberOfForms)
//   return res.map((form) => formikValues[form])
// }

/**
 *
 * @param {*} forms
 * @returns object {forms and IDS to keep track of last added ids}
 */
export function addAttrAndIds(forms) {
  let ids = []
  const addedFroms = forms.map((form) => {
    let _id = uuidv4()
    ids.push(_id)
    return { ...form, attributes: { _id, type: 'EVENT' }, children: [] }
  })
  return { addedFroms, ids }
}

/**
 *
 * @param {*} formik
 * @param {*} numberOfForms
 * @returns verify if all (selected fomrs inputs) ecartType are greater then zero
 */

export function allEcartTypeAreGtThenZero(formikValues, numberOfForms) {
  return getAllFormsKeys(formikValues, numberOfForms).every(
    (form) => Number(formikValues[`${form}`].ecartType) >= 0
  )
}
/**
 *
 * @param {*} formik
 * @param {*} numberOfForms
 * @returns get only forms that was typed by user
 */
export function giveOnlySelectedFormInputs(formikValues, numberOfForms) {
  const res = {}
  getAllFormsKeys(formikValues, numberOfForms).forEach(
    (key) => (res[`${key}`] = formikValues[`${key}`])
  )
  return res
}

/**
 *
 * @param {*} formik
 * @param {*} numberOfForms
 * @returns
 */

export function addEstimationToInput(formikValues, numberOfForms) {
  const ret = {}
  const res = giveOnlySelectedFormInputs(formikValues, numberOfForms)
  return Object.keys(res).map((key) => ({
    ...res[`${key}`],
    ...calculatePercentage({
      probability: res[`${key}`].probability,
      reliability: res[`${key}`].ecartType
    })
  }))
  // return ret
}

export function addEstimation(forms) {
  return forms.map((form) => {
    const probability = calculatePercentage({
      probability: form.probability,
      reliability: form.ecartType
    })
    return { ...form, attributes: { ...form.attributes, ...probability } }
  })
}

/**
 *
 * @param {*} formikValues
 * @param {*} numberOfForms
 * @returns sum of probabilities to verify if it is 100
 */

export function sumOfProbabilities(formikValues, numberOfForms) {
  return Object.keys(formikValues)
    .filter((str) => str !== 'event')
    .slice(0, numberOfForms)
    .map((name) => formikValues[`${name}`].probability)
    .map((proba) => Number(proba))
    .reduce((acc, curr) => (acc += curr), 0)
}

/**
 *
 * @param {*} param0
 * @returns calculates percentage
 */
export function calculatePercentage({ probability, reliability }) {
  // ensureParam(probability, 'probability')
  // ensureParam(reliability, 'reliability')
  const getResult = () => ((reliability / 100) * probability).toFixed(2) // of percentage
  const toInt = (res) => parseInt(res, 10)
  const result = toInt(getResult())
  // based on reliability
  const from = toInt(probability) - result
  const to = toInt(probability) + result

  return { from, to }
}

/**
 *
 * @param {*} nodes
 * @returns Boolean | true if error
 */

export function ecartTypeRangeError(nodes) {
  // if false it is an error
  const noError = nodes.every((elem) => {
    const from = elem?.attributes?.from
    const to = elem?.attributes?.to
    return from >= 0 && to < 100
  })
  return noError === false
}

// export function ecartTypeRangeError(nodes) {
//   nodes.forEach((elem) => {
//     const from = elem?.attributes?.from
//     const to = elem?.attributes?.to
//     if (from > 100) return true
//     if (to < 0) return true
//   })
//   return false
// }
